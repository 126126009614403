import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { AsioPrimitivesProvider } from '@ariessolutionsio/primitives-richproducts';
import { AsioClient, AsioProvider } from '@ariessolutionsio/providers-richproducts';
import { Toast } from '@ariessolutionsio/react-ecomm-ui/dist';
import { ThemeContext } from '@ariessolutionsio/react-ecomm-ui/dist/components/contexts/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { setCookie } from 'cookies-next';
import { appWithTranslation } from 'next-i18next';
import { TrackJS } from 'trackjs';
import Toaster from 'components/commercetools-ui/toaster';
import { FrontasticProvider, fetchApiHub } from 'frontastic';
import '@ariessolutionsio/react-ecomm-ui/dist/styles/aries-ui.css';
import '../styles/app.css';
import { LoadingProvider } from 'frontastic/provider/loading/LoadingProvider';
import { defaultTheme } from '../styles/themes/default';
import { sdk } from '@/sdk';

const queryClient = new QueryClient();
const asioClient = new AsioClient('COMMERCETOOLS');

// @ts-ignore
function FrontasticStarter({ Component, pageProps }) {
  const { locale } = useRouter();
  sdk.configureForNext(locale as string);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('REACTReady'));
    TrackJS.install({ token: process.env.TRACKJS_TOKEN });
  }, []);

  return (
    <Token>
      <QueryClientProvider client={queryClient}>
        <AsioProvider client={asioClient}>
          <AsioPrimitivesProvider>
            <LoadingProvider>
              <FrontasticProvider>
                <ThemeContext.Provider value={{ theme: defaultTheme }}>
                  <Component {...pageProps} />
                </ThemeContext.Provider>
                {/* Should remove it to use the new Toaster from @aries/ui */}
                <Toaster />
                <Toast.Toaster />
              </FrontasticProvider>
            </LoadingProvider>
          </AsioPrimitivesProvider>
        </AsioProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Token>
  );
}

const Token = ({ children }) => {
  useEffect(() => {
    const getTokenDetails = async () => {
      const res = await fetchApiHub('/action/account/getTokenDetails', { method: 'POST' }, {});

      setCookie('asio_session', JSON.stringify(res));
      return res;
    };

    getTokenDetails();
  }, []);

  return <>{children}</>;
};

export default appWithTranslation(FrontasticStarter);
